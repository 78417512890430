@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.goods-swiper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
.goods-swiper > .swiper-container {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.goods-swiper > .sold-out {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.5) url(../../static/img/icon01.da5f90f1.png) center no-repeat;
  background-size: 70%;
  width: 100%;
  height: 100%;
}
.goods-info {
  background-color: #fff;
}
.goods-info > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.goods-info > .header > .label,
.goods-info > .header > .price-wrap,
.goods-info > .header > .countdown-wrap {
  min-height: 1rem;
  padding: 0 0.2rem;
}
.goods-info > .header > .label {
  -webkit-box-flex: 0.54;
  -webkit-flex: 0.54 0.54 1%;
          flex: 0.54 0.54 1%;
}
.goods-info > .header > .price-wrap {
  -webkit-box-flex: 0.54;
  -webkit-flex: 0.54 0.54 1%;
          flex: 0.54 0.54 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: #e52779;
  background-color: var(--color-main, #e52779);
}
.goods-info > .header > .price-wrap .price {
  color: #fff;
  line-height: 1.2;
}
.goods-info > .header > .price-wrap > .price {
  font-size: 0.4rem;
}
.goods-info > .header > .price-wrap > .old-price {
  color: #fff;
  font-size: 0.22rem;
  line-height: 1.2;
}
.goods-info > .header > .countdown-wrap {
  -webkit-box-flex: 0.46;
  -webkit-flex: 0.46 0.46 1%;
          flex: 0.46 0.46 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #ffcc33;
}
.goods-info > .header > .countdown-wrap > .countdown {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #f00;
}
.goods-info > .body {
  padding: 0.3rem 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.goods-info > .body > .label-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
.goods-info > .body > .label-wrap > .name {
  font-size: 0.32rem;
}
.goods-info > .body > .label-wrap > .desc {
  color: #868e96;
  line-height: 1.2;
  margin-top: 0.2rem;
}
.goods-info > .body > .share {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.22rem;
}
.goods-info > .body > .share > .f-icon {
  font-size: 0.6rem;
  line-height: 1;
  margin-bottom: 0.1rem;
}
.goods-info > .body > .share.active {
  color: #f00;
}
.goods-info > .footer {
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.content-input[data-v-96253f0e] {
  background-color: #e9ecef !important;
  border-radius: 0.1rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.feedback-item {
  background-color: #fff;
  padding: 0.2rem;
  display: block;
}
.feedback-item > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.feedback-item > .header > .label {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  font-weight: bold;
}
.feedback-item > .header > .extra {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  color: #999;
}
.feedback-item > .body {
  color: #999;
}
.feedback-item + .feedback-item {
  margin-top: 0.2rem;
}

/*# sourceMappingURL=preview.9913de80.css.map*/