@import "@/assets/style/_index.scss";









































































.feedback-item {
  background-color: #fff;
  padding: $padding;
  display: block;

  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .label {
      flex: 1 1 1%;
      font-weight: bold;
    }
    > .extra {
      flex: 0 0 auto;
      color: $color-text-minor;
    }
  }

  > .body {
    color: $color-text-minor;
  }

  + .feedback-item {
    margin-top: $margin;
  }
}
